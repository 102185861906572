import { ICellRendererParams } from '@ag-grid-community/core';
import { isAirbyteStorageType } from '../../connectors/util';
import { AirbyteActionsRenderer } from './AirbyteActionsRenderer';

interface ActionsRendererProps extends ICellRendererParams {
  data: any;
}

export const ActionsRenderer = (props: ActionsRendererProps) => {
  const { data } = props;

  if (!data) return null;

  if (isAirbyteStorageType(data.connectorType)) {
    return <AirbyteActionsRenderer {...props} />;
  }
  return null;
};
