import { IconTransform } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { IconOrangeWarning } from '../../../../../../../design-system';
import {
  Alert,
  Box,
  Center,
  Loader,
  Tabs,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../../../../../design-system/v2';
import {
  DataProperties,
  IOSchema,
  OperatorCategory,
  WorkflowRunOperatorStatus,
} from '../../../../../../../generated/api';
import {
  useDebugRunStatusQuery,
  useInitDebugMutation,
} from '../../../../../../../queries/workflows/debug';
import { useGetWorkflowDetailsQuery } from '../../../../../../../queries/workflows/detail/detail';
import { useGetWorkflowDagNodesSchemaV2Query } from '../../../../../../../queries/workflows/operators';
import { Mode } from '../../../../utils';
import { DagNodeSchemaError } from './DagNodeSchemaError';
import { DataPreviewContainer } from './DataPreview.container';
import { WorkflowDagNodeSchema } from './WorkflowDagNodeSchema';

interface WorkflowDagNodeSchemaContainerProps {
  workflowId: string;
  nodeId: string;
  runId?: string;
  prevNodeId?: string;
  operatorCategory?: OperatorCategory;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  isFormDirty?: boolean;
  isFormSaving?: boolean;
  mode?: Mode;
}

const renderSchemaContent = (
  schemas: IOSchema[] | IOSchema[][],
  renderContent: (schema: any) => JSX.Element,
  type: 'Input' | 'Output',
  operatorCategory?: OperatorCategory,
  hideSection?: boolean,
) => {
  if (hideSection) {
    return <></>;
  }

  if (schemas.length > 1) {
    return (
      <Tabs defaultValue={`${type}1`} h="100%">
        <Tabs.List mb="sm">
          {schemas.map((_, index) => (
            <Tabs.Tab value={`${type}${index + 1}`} key={`${type}${index + 1}`}>
              <Text variant="overline" tt="uppercase" color="gray.7">
                {`${type} Preview ${index + 1}`}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {schemas.map((schema, index) => (
          <Tabs.Panel
            value={`${type}${index + 1}`}
            key={`${type}Panel${index + 1}`}
            h="calc(100% - 46px)"
          >
            {renderContent(schema)}
          </Tabs.Panel>
        ))}
      </Tabs>
    );
  }

  return (
    <Vertical h="100%">
      <Text variant="overline" tt="uppercase" color="gray.7">
        {`${operatorCategory !== OperatorCategory.Process ? 'Data' : type} Preview`}
      </Text>
      {schemas.map((schema, idx) => (
        <Box key={idx} h="100%">
          {renderContent(schema)}
        </Box>
      ))}
    </Vertical>
  );
};

export const WorkflowDagNodeSchemaContainer = ({
  workflowId,
  nodeId,
  runId,
  prevNodeId,
  operatorCategory,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  isFormDirty,
  isFormSaving,
  mode,
}: WorkflowDagNodeSchemaContainerProps) => {
  const theme = useMarkovTheme();

  const { isLoading, isFetching, isError, data } = useGetWorkflowDagNodesSchemaV2Query(
    workflowId,
    nodeId,
    mode === Mode.Debug ? runId : undefined,
  );
  const {
    isLoading: runStatusLoading,
    isError: runStatusError,
    data: runStatus,
  } = useDebugRunStatusQuery(workflowId, runId);
  const {
    isLoading: loadingWFDetails,
    isError: wfDetailsError,
    data: wfDetails,
  } = useGetWorkflowDetailsQuery(workflowId);
  const { mutateAsync: initDebugWorkflow } = useInitDebugMutation(workflowId);

  const handleDebug = () => {
    initDebugWorkflow({});
  };

  if (isFormDirty && operatorCategory === OperatorCategory.Source) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Alert icon={<IconTransform size={16} color={theme.colors.gray[9]} />}>
          <Text variant="bodyLong02" color="gray.9">
            You&apos;ve made changes. Please apply them to see the preview.
          </Text>
        </Alert>
      </Vertical>
    );
  }

  if (isFormSaving || isLoading || isFetching) {
    return (
      <Vertical h="100%" align="center" justify="center">
        <Loader variant="dots" size="xl" />
        <Text variant="subTitle03">Loading data for the preview</Text>
      </Vertical>
    );
  }

  const ioSchema = data?.nodeSchemas;
  const operatorStatus = runStatus?.operatorsStatus?.find(opStatus => opStatus.nodeId === nodeId);
  const nodeStatus = operatorStatus?.statusDetails.status;

  if (!ioSchema || !ioSchema[nodeId] || isError) {
    return <DagNodeSchemaError workflowId={workflowId} />;
  }

  const inputSchemas = Object.values(ioSchema[nodeId]?.inputs ?? {});
  const outputSchemas = (ioSchema[nodeId]?.outputs ?? []).filter(
    (output: IOSchema) => output.type !== 'MODEL',
  );
  const showDataPreview =
    (data.dagHash === runStatus?.dagHash || mode === Mode.Debug) &&
    nodeStatus === WorkflowRunOperatorStatus.Success &&
    !!runId;

  //UX for multiple inputs coming from a single previous node needs to be thought of.
  const renderInputContent = (inputSchema: IOSchema[]) => (
    <Vertical h="100%">
      {showDataPreview ? (
        <DataPreviewContainer
          operatorCategory={operatorCategory}
          workflowId={workflowId}
          runId={runId ?? ''}
          nodeId={prevNodeId ?? ''}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          allowColumnSelection={allowColumnSelection}
          schema={(inputSchema[0]?.properties as DataProperties)?.columnSchema ?? []}
          modifiedColumns={(inputSchema[0]?.properties as DataProperties)?.schemaChanges?.removed}
          shadingColor={theme.colors.red[0]}
        />
      ) : (
        <WorkflowDagNodeSchema
          workflowId={workflowId}
          schema={(inputSchema[0]?.properties as DataProperties)?.columnSchema ?? []}
          data={(inputSchema[0]?.properties as DataProperties)?.sampleData ?? []}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          allowColumnSelection={allowColumnSelection}
          modifiedColumns={(inputSchema[0]?.properties as DataProperties)?.schemaChanges?.removed}
          shadingColor={theme.colors.red[0]}
          type="Input"
        />
      )}
    </Vertical>
  );

  const renderOutputContent = (outputSchema: IOSchema) => (
    <Vertical h="100%">
      {showDataPreview ? (
        <DataPreviewContainer
          operatorCategory={operatorCategory}
          workflowId={workflowId}
          runId={runId ?? ''}
          nodeId={nodeId}
          selectedColumns={[]}
          setSelectedColumns={noop}
          allowColumnSelection={false}
          schema={(outputSchema.properties as DataProperties)?.columnSchema ?? []}
          modifiedColumns={
            operatorCategory === OperatorCategory.Source
              ? []
              : (outputSchema.properties as DataProperties)?.schemaChanges?.added
          }
          shadingColor={theme.colors.green[0]}
        />
      ) : (
        <WorkflowDagNodeSchema
          workflowId={workflowId}
          schema={(outputSchema.properties as DataProperties)?.columnSchema ?? []}
          data={(outputSchema.properties as DataProperties)?.sampleData ?? []}
          selectedColumns={[]}
          setSelectedColumns={noop}
          allowColumnSelection={false}
          modifiedColumns={
            operatorCategory === OperatorCategory.Source
              ? []
              : (outputSchema.properties as DataProperties)?.schemaChanges?.added
          }
          shadingColor={theme.colors.green[0]}
          type="Output"
        />
      )}
    </Vertical>
  );

  if (inputSchemas.length === 0 && operatorCategory !== OperatorCategory.Source) {
    return (
      <Center w="100%" h="100%">
        <Vertical align="center" spacing={0}>
          <IconOrangeWarning width={32} height={32} />
          <Text variant="subTitle02" color="gray.7">
            Input data missing
          </Text>
          <Text variant="small01" color="gray.6" align="center">
            This operation hasn&apos;t received valid input yet. Please connect it to a node that
            provides the right input!
          </Text>
        </Vertical>
      </Center>
    );
  }

  return (
    <Vertical h="100%">
      {renderSchemaContent(
        inputSchemas,
        renderInputContent,
        'Input',
        operatorCategory,
        operatorCategory === OperatorCategory.Source,
      )}
      {renderSchemaContent(
        outputSchemas,
        renderOutputContent,
        'Output',
        operatorCategory,
        operatorCategory === OperatorCategory.Sink,
      )}
    </Vertical>
  );
};
