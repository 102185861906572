import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { first, get } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Center,
  EllipsisText,
  Horizontal,
  Loader,
  Text,
} from '../../../../../../../design-system/v2';
import { Table, TableThemes } from '../../../../../../../design-system/v2/core/data-display/table';
import { OperatorCategory } from '../../../../../../../generated/api';
import { parseCSVString } from '../../../../../../../lib/fileparse';
import { useNodeDataPreviewQuery } from '../../../../../../../queries/workflows/operators';
import { useFolderFilePreview } from '../../../../../../data-sources/modals/use-folder-file-preview';
import { useFilePreview } from '../../../../debug-panel/logs-modal/previews/use-file-preview';
import { useJsonPreview } from '../../../../debug-panel/logs-modal/previews/use-json-preview';
import { AUDIO_URL_REGEX } from '../../../../utils';
import { HeaderWithCheckbox } from './NodeSchemaPreview';
import { PreviewAudioButton } from './PreviewAudioButton';
import { extractMkvFolderFilePreviewData } from './util';

interface DataPreviewContainerProps {
  workflowId: string;
  runId: string;
  nodeId: string;
  selectedColumns?: string[];
  setSelectedColumns?: (values: string[]) => void;
  allowColumnSelection?: boolean;
  schema: {
    name: string;
    type: string;
  }[];
  operatorCategory?: OperatorCategory;
  modifiedColumns?: string[];
  shadingColor?: string;
}

export const DataPreviewContainer = ({
  workflowId,
  runId,
  nodeId,
  selectedColumns,
  setSelectedColumns,
  allowColumnSelection,
  schema,
  operatorCategory,
  modifiedColumns,
  shadingColor,
}: DataPreviewContainerProps) => {
  const openFilePreview = useFilePreview();
  const openJsonPreview = useJsonPreview();

  // TODO: Remove operator category from data preview,HACKY FIX TO MAKE SOURCE OPERATORS WORK, MERGE PREVIEW+DAGIOSCHEMA into one api.
  const {
    isLoading,
    isFetching,
    error,
    data: nodeDataPreview,
  } = useNodeDataPreviewQuery(workflowId, runId, nodeId, operatorCategory ?? '');
  const [isDataParsing, setIsDataParsing] = useState(true);
  const [isParsingError, setIsParsingError] = useState(false);
  const [parsedData, setParsedData] = useState<string[][] | undefined>();

  const validSelectedColumns =
    selectedColumns?.filter(col => first(parsedData)?.includes(col)) ?? [];

  const toggleColumnSelection = (colId: string) => {
    if (setSelectedColumns && selectedColumns) {
      const index = validSelectedColumns.indexOf(colId);
      if (index > -1) {
        setSelectedColumns(validSelectedColumns.filter((id: string) => id !== colId));
      } else {
        return setSelectedColumns([...validSelectedColumns, colId]);
      }
    }
  };

  useEffect(() => {
    setIsDataParsing(true);
    setIsParsingError(false);

    if (nodeDataPreview) {
      parseCSVString(nodeDataPreview.data ?? [''], ',')
        .then(results => {
          setParsedData(results);

          setIsDataParsing(false);
          setIsParsingError(false);
        })
        .catch(() => {
          setIsParsingError(true);
        });
    }
  }, [nodeDataPreview?.data]);

  if ((isLoading || isFetching || isDataParsing) && !error) {
    return (
      <Center mih={200} w="100%">
        <Loader />
      </Center>
    );
  }

  if (error || isParsingError) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">
          {error?.response?.data.detail.msg ??
            'Error fetching data preview. Try running debug again'}
        </Alert>
      </Center>
    );
  }

  if (!parsedData) {
    return (
      <Center mih={200} w="100%">
        <Alert color="red">Data preview not found. Must be an error</Alert>
      </Center>
    );
  }

  const columns: ColDef[] =
    first(parsedData)?.map(datum => {
      const type = schema.find(schemaItem => schemaItem.name === datum)?.type;

      if (type === 'S3_FILE' || type === 'MKV_FILE') {
        return {
          colId: datum,
          field: datum,
          headerName: datum,
          headerComponent: allowColumnSelection
            ? () => (
                <HeaderWithCheckbox
                  column={{ name: datum, type: datum }}
                  toggleColumnSelection={toggleColumnSelection}
                  isSelected={(selectedColumns ?? []).includes(datum)}
                />
              )
            : () => (
                <Text variant="subTitle04" color="gray.9">
                  {datum}
                </Text>
              ),
          filter: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellRenderer: ({ value, data }: ICellRendererParams) => {
            const openFolderFilePreview = useFolderFilePreview(get(data, 'Name', ''));
            const handlePreview = () => {
              openFilePreview(workflowId, value);
            };

            const handleMkvFolderFilePreview = () => {
              const { resourceId, fileType, filePath } = extractMkvFolderFilePreviewData(data);
              openFolderFilePreview(resourceId, fileType, filePath);
            };

            return (
              <Button onClick={type === 'S3_FILE' ? handlePreview : handleMkvFolderFilePreview}>
                Preview
              </Button>
            );
          },
        };
      }

      if (type === 'JSON') {
        return {
          colId: datum,
          field: datum,
          headerName: datum,
          headerComponent: allowColumnSelection
            ? () => (
                <HeaderWithCheckbox
                  column={{ name: datum, type: datum }}
                  toggleColumnSelection={toggleColumnSelection}
                  isSelected={(selectedColumns ?? []).includes(datum)}
                />
              )
            : () => (
                <Box>
                  <Text variant="subTitle04" color="gray.9">
                    {datum}
                  </Text>
                  <Text variant="small03" color="gray.6">
                    Json
                  </Text>
                </Box>
              ),
          filter: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellRenderer: ({ value }: ICellRendererParams) => {
            const handlePreview = () => {
              openJsonPreview(value);
            };
            return (
              <Box>
                <Button onClick={handlePreview}>Preview</Button>
              </Box>
            );
          },
        };
      }

      return {
        colId: datum,
        field: datum,
        headerName: datum,
        cellStyle: modifiedColumns?.includes(datum)
          ? { backgroundColor: shadingColor ?? 'none' }
          : { backgroundColor: 'none' },
        headerComponent: allowColumnSelection
          ? () => (
              <HeaderWithCheckbox
                column={{ name: datum, type: datum }}
                toggleColumnSelection={toggleColumnSelection}
                isSelected={(selectedColumns ?? []).includes(datum)}
              />
            )
          : () => (
              <Text variant="subTitle04" color="gray.9">
                {datum}
              </Text>
            ),
        filter: false,
        sortable: false,
        minWidth: 100,
        flex: 1,
        // This should not be needed, but for some reason, values are coming undefined
        // FIXIT:: remove cell renderer
        cellRenderer: ({ value, data, colDef }: ICellRendererParams) => {
          // TODO: Check why empty string is used to index data
          const cellValue = (value ?? data[colDef?.colId ?? ''] ?? '') as string;
          const isAudioFileUrl = AUDIO_URL_REGEX.test(cellValue);

          return (
            <Horizontal noWrap spacing={0} h="100%">
              <EllipsisText variant="bodyLong02" closeDelay={100} pt={0}>
                {cellValue}
              </EllipsisText>
              {isAudioFileUrl && (
                <Box>
                  <PreviewAudioButton srcUrl={cellValue} />
                </Box>
              )}
            </Horizontal>
          );
        },
      };
    }) ?? [];

  const rowData = parsedData?.slice(1).map(parsedDataRow =>
    parsedDataRow.reduce((prev, curr, idx) => {
      const colId = columns[idx] ? columns[idx].colId : null;
      if (!colId) {
        return prev;
      }

      return {
        ...prev,
        [colId]: curr,
      };
    }, {}),
  );

  return <Table rowHeight={44} theme={TableThemes.THEME_V2} rowData={rowData} columns={columns} />;
};
