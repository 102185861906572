import {
  Badge,
  Box,
  Horizontal,
  Image,
  LinkAnchor,
  Text,
  Vertical,
} from '../../../../design-system/v2';
import {
  GetWorkspaceCurrentSubscriptionDetailResponseModel,
  PaymentSubscriptionStatusType,
  PaymentSubscriptionType,
} from '../../../../generated/api';
import { formatDateLong, toPlural } from '../../../../lib/ui';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import {
  calculateTrialDaysRemaining,
  getPlanStatus,
  PLAN_HORIZONTAL_SPACING,
  PLAN_ICON_WIDTH,
  subscriptionPlanDetails,
} from '../../../subscriptions/util';

interface PlanDetailsProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
}

const PlanDetails = ({ subscriptionDetail }: PlanDetailsProps) => {
  const { getSubscriptionPlansRoute } = useAbsoluteRoutes();

  const subscriptionStatusType = subscriptionDetail.status;
  const subscriptionType = subscriptionDetail.subscriptionType;

  if (subscriptionType === PaymentSubscriptionType.EnterprisePlan) {
    // Currently we do not show any plan details in the Enterprise Plan.
    return <></>;
  }

  if (subscriptionStatusType === PaymentSubscriptionStatusType.Trialing) {
    const trialDaysRemaining = calculateTrialDaysRemaining(subscriptionDetail.currentPeriodEnd);
    const trialDaysText = toPlural(trialDaysRemaining, 'day');

    const trialText =
      trialDaysRemaining > 0 ? (
        <Text variant="bodyShort03">
          Your free trial ends in{' '}
          <Text variant="subTitle04" span>
            {trialDaysText}
          </Text>
          .
        </Text>
      ) : (
        <Text variant="bodyShort03">Your free trial has ended.</Text>
      );

    return (
      <Horizontal spacing="xs">
        {trialText}
        <LinkAnchor
          to={getSubscriptionPlansRoute()}
          sx={theme => ({ color: theme.colors.blue[7] })}
        >
          Upgrade now
        </LinkAnchor>
      </Horizontal>
    );
  }

  if (subscriptionStatusType === PaymentSubscriptionStatusType.Active) {
    const currentPeriodEnd = subscriptionDetail.currentPeriodEnd;
    const currentPeriodEndDate = new Date(currentPeriodEnd * 1000);
    const formattedCurrentPeriodEnd = formatDateLong(currentPeriodEndDate);

    return <Text>Renews on {formattedCurrentPeriodEnd}</Text>;
  }

  if (subscriptionStatusType === PaymentSubscriptionStatusType.Canceled) {
    return (
      <Text>Your previous plan is canceled. Please choose a plan that best suits your needs.</Text>
    );
  }

  return (
    <Text>
      Unknown subscription status. Please contact support@markovml.com in case of any queries.
    </Text>
  );
};

interface ActivePlanSectionProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
}

export const PlanSection = ({ subscriptionDetail }: ActivePlanSectionProps) => {
  const { getSubscriptionPlansRoute } = useAbsoluteRoutes();
  const { iconUrl, planName } = subscriptionPlanDetails[subscriptionDetail.subscriptionType];

  const isCanceled = subscriptionDetail.status === PaymentSubscriptionStatusType.Canceled;
  const planStatus = getPlanStatus(subscriptionDetail.status);

  return (
    <Box>
      <Horizontal spacing="lg">
        <Text color="gray.6">Your Plan</Text>
        <LinkAnchor to={getSubscriptionPlansRoute()}>
          <Text variant="textLink" color="blue.6" sx={theme => ({ fontSize: theme.fontSizes.sm })}>
            View plans
          </Text>
        </LinkAnchor>
      </Horizontal>
      <Vertical pt="md" spacing="md">
        <Horizontal spacing={PLAN_HORIZONTAL_SPACING}>
          <Image src={iconUrl} width={PLAN_ICON_WIDTH} />
          <Text variant="subTitle02" tt="capitalize">
            {planName}
          </Text>
          <Badge
            variant="outline"
            color={isCanceled ? 'red.6' : 'green.6'}
            bg={isCanceled ? 'red.0' : 'green.0'}
            tt="none"
          >
            <Text variant="small02">{planStatus}</Text>
          </Badge>
        </Horizontal>
        <Vertical pl={PLAN_ICON_WIDTH + PLAN_HORIZONTAL_SPACING} spacing="xs">
          <PlanDetails subscriptionDetail={subscriptionDetail} />
        </Vertical>
      </Vertical>
    </Box>
  );
};
